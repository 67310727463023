<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="処分事業者編集" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>

        <!-- 会社情報 ======================================================================== -->
        <div class="l-contents l-box">
          <div class="l-box">
            <h3 class="c-lead icon">会社情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>会社名</label>
              </div>
              <div class="c-inputWrap__items">
                <div>
                  <Selectbox
                    class="l-area"
                    name="prefecture"
                    placeholder="選択してください"
                    :value.sync="form.legalPersonalityTypeId"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.legalPersonalityTypeId.isValid"
                    :errMsg.sync="validate.legalPersonalityTypeId.errMsg"
                  >
                    <option
                      v-for="type in legalPersonalityTypes"
                      :key="type.id"
                      :value="type.id"
                    >
                      {{ type.name }}
                    </option>
                  </Selectbox>
                  <InputText
                    :value.sync="form.companyName"
                    inputType="text"
                    placeholder="会社名/営業所名"
                    tooltip="100文字以内で入力してください"
                    :validation="validateMaxLen"
                    :validateArg="100"
                    :isValid.sync="validate.companyName.isValid"
                    :errMsg.sync="validate.companyName.errMsg"
                  />
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>住所</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>郵便番号</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div class="c-inputWrap__items__cols center sm-mgn">
                      <InputText
                        :value.sync="form.addressInfo.zipCode"
                        inputType="number"
                        placeholder="1234567(ハイフンなし)"
                        tooltip="7桁の半角数字で入力してください。"
                        :validation="validateNumberEqualLen"
                        :validateArg="7"
                        :isValid.sync="validate.zipCode.isValid"
                        :errMsg.sync="validate.zipCode.errMsg"
                      />
                      <div
                        class="c-btn secondary sm"
                        @click="searchAddressHandler(form.addressInfo)"
                      >
                        住所自動入力
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>都道府県</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <Selectbox
                        class=""
                        name="prefecture"
                        :value.sync="form.addressInfo.selectedPrefectureTemp"
                        :validation="validateJustBlank"
                        :isValid.sync="validate.prefecturesId.isValid"
                        :errMsg.sync="validate.prefecturesId.errMsg"
                      >
                        <option
                          v-for="prefecture in prefectures"
                          :key="prefecture.id"
                          :value="prefecture.name"
                        >
                          {{ prefecture.name }}
                        </option>
                      </Selectbox>
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>市町村</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <InputText
                        :value.sync="form.addressInfo.address"
                        inputType="text"
                        placeholder="市町村"
                        tooltip="100文字以内で入力してください"
                        :validation="validateMaxLen"
                        :validateArg="100"
                        :isValid.sync="validate.address.isValid"
                        :errMsg.sync="validate.address.errMsg"
                      />
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>番地建物名号室</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <InputText
                        :value.sync="form.addressInfo.buildingName"
                        inputType="text"
                        placeholder="番地建物名号室"
                        tooltip="100文字以内で入力してください"
                        :validation="validateMaxLen"
                        :validateArg="100"
                        :isValid.sync="validate.buildingName.isValid"
                        :errMsg.sync="validate.buildingName.errMsg"
                      />
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>電話番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="form.tel"
                  inputType="number"
                  placeholder="電話番号"
                  tooltip="半角数値で入力してください"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="validate.tel.isValid"
                  :errMsg.sync="validate.tel.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>FAX番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="form.fax"
                  inputType="number"
                  placeholder="FAX番号"
                  tooltip="半角数値で入力してください"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="validate.fax.isValid"
                  :errMsg.sync="validate.fax.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>担当者名</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="form.staffName"
                  inputType="text"
                  placeholder="担当者名"
                  tooltip="50文字以内で入力してください"
                  :validation="validateMaxLen"
                  :validateArg="50"
                  :isValid.sync="validate.staffName.isValid"
                  :errMsg.sync="validate.staffName.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>担当者メールアドレス</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="form.staffMail"
                  inputType="mail"
                  placeholder="担当者メールアドレス"
                  tooltip="メールアドレスを入力してください"
                  :validation="validateMail"
                  :isValid.sync="validate.staffMail.isValid"
                  :errMsg.sync="validate.staffMail.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>事業者コード</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="form.businessCode"
                  inputType="text"
                  placeholder="事業者コード"
                  tooltip="半角6文字で入力してください"
                  :validation="validateHalfSizeTextEqualLen"
                  :validateArg="6"
                  :isValid.sync="validate.businessCode.isValid"
                  :errMsg.sync="validate.businessCode.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>得意とするCBA品目</label>
              </div>
              <div class="c-inputWrap__items">
                <Selectbox
                  class="l-area"
                  v-for="(list, index) in form.cbaItemIdList"
                  :key="'cbaItemIdList-' + index"
                  name="prefecture"
                  :value.sync="list.cbaItemId"
                  :validation="validateJustBlank"
                >
                  <option
                    v-for="item in cbaItems"
                    :key="'cbaItem-' + item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </Selectbox>
                <div
                  class="c-btn secondary func add"
                  @click="addCbaItemHandler"
                >
                  CBA品目を追加
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>備考</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="l-area">
                  <MyTextarea
                    :value.sync="form.remarks"
                    rows="6"
                    placeholder="入力してください"
                    :validation="validateJustBlank"
                  />
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>許可証情報</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <div class="c-radio">
                    <input
                        id="is-licence"
                        type="radio"
                        :value="true"
                        v-model="hasDisposalLicence"
                    />
                    <label class="c-radio__label" for="is-licence">
                      <span class="c-radio__box"></span>
                      有り
                    </label>
                  </div>
                  <div class="c-radio">
                    <input
                        id="is-not-licence"
                        type="radio"
                        :value="false"
                        v-model="hasDisposalLicence"
                    />
                    <label class="c-radio__label" for="is-not-licence">
                      <span class="c-radio__box"></span>
                      無し
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.l-contents -->

        <!-- 許可証情報 ======================================================================== -->
        <template v-if="hasDisposalLicence">
          <div
            v-for="(licenceInfo, licenceIndex) in form.disposalLicenceCreateList"
            :key="'licenceInfo-' + licenceIndex"
            class="l-contents l-each"
          >
            <div class="l-box u-space-btwn">
              <h3 class="c-lead icon">許可証情報{{ licenceIndex + 1 }}</h3>
              <div class="c-btn secondary sm delete"
                @click="deleteLicence(licenceIndex)"
              >
                削除
              </div>
            </div>
            <div>

              <div class="c-inputWrap" v-if="licenceInfo.disposalLicenceId">
                <div class="c-inputWrap__label">
                  <label>廃棄物区分</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ licenceInfo.wasteTypeId === wasteType.INDUSTRIAL_WASTE ? "産業廃棄物" : "一般廃棄物" }}
                </div>
              </div>
              <div class="c-inputWrap" v-else>
                <div class="c-inputWrap__label required">
                  <label>廃棄物区分</label>
                </div>
                <div class="c-inputWrap__items">
                  <div class="c-inputWrap__items__cols l-area">
                    <div class="c-radio" v-for="wt in wasteTypeList" :key="wt.id">
                      <input
                        :id="'waste-type-' + wt.id + '-licence-' + licenceIndex"
                        type="radio"
                        :name="`waste-type-${licenceIndex}`"
                        :value="wt.id"
                        v-model="licenceInfo.wasteTypeId"
                      />
                      <label class="c-radio__label" :for="'waste-type-' + wt.id + '-licence-' + licenceIndex">
                        <span class="c-radio__box"></span>
                        {{ wt.label }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="c-inputWrap" v-if="from !== 'step2-input'">
                <div class="c-inputWrap__label required">
                  <label>ステータス</label>
                </div>
                <div class="c-inputWrap__items">
                  <div class="c-inputWrap__items__cols">
                    <div class="c-radio">
                      <input
                        :id="`status-active-${licenceIndex}`"
                        type="radio"
                        :name="`status-${licenceIndex}`"
                        :value="true"
                        v-model="licenceInfo.isActive"
                        @change="() => changeStatusActiveHandler(licenceInfo)"
                      />
                      <label
                        class="c-radio__label"
                        :for="`status-active-${licenceIndex}`"
                      >
                        <span class="c-radio__box"></span>
                        有効
                      </label>
                    </div>
                    <div class="c-radio">
                      <input
                        :id="`status-non-active-${licenceIndex}`"
                        type="radio"
                        :name="`status-${licenceIndex}`"
                        :value="false"
                        v-model="licenceInfo.isActive"
                        @change="() => changeStatusActiveHandler(licenceInfo)"
                      />
                      <label
                        class="c-radio__label"
                        :for="`status-non-active-${licenceIndex}`"
                      >
                        <span class="c-radio__box"></span>
                        無効
                      </label>
                    </div>
                    <div class="c-radio">
                      <input
                        :id="`status-update-${licenceIndex}`"
                        type="radio"
                        :name="`status-${licenceIndex}`"
                        :value="true"
                        v-model="licenceInfo.isUpdate"
                        @change="() => changeStatusUpdateHandler(licenceInfo)"
                      />
                      <label
                        class="c-radio__label"
                        :for="`status-update-${licenceIndex}`"
                      >
                        <span class="c-radio__box"></span>
                        更新中
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label required">
                  <label>許可番号</label>
                </div>
                <div class="c-inputWrap__items">
                  <InputText
                    :value.sync="licenceInfo.licenceNo"
                    :inputType="licenceInfo.wasteTypeId === wasteType.INDUSTRIAL_WASTE ? 'number' : 'text'"
                    placeholder="許可番号"
                    :tooltip="licenceInfo.wasteTypeId === wasteType.INDUSTRIAL_WASTE ? '最大11文字の半角数値で入力してください' : '最大50文字で入力してください'"
                    :validation="licenceInfo.wasteTypeId === wasteType.INDUSTRIAL_WASTE ? validateNumberMaxLen : validateMaxLen"
                    :validateArg="licenceInfo.wasteTypeId === wasteType.INDUSTRIAL_WASTE ? 11 : 50"
                    :blur="() => licenceNoBlurHandler(licenceIndex)"
                    :isValid.sync="getLicenceForm(licenceIndex).licenceNo.isValid"
                    :errMsg.sync="getLicenceForm(licenceIndex).licenceNo.errMsg"
                  />
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label required">
                  <label>許可証期限</label>
                </div>
                <div class="c-inputWrap__items">
                  <InputDate
                    :value.sync="licenceInfo.licenceExpiredDate"
                    placeholder="選択してください"
                    :validation="validateJustBlank"
                    :isValid.sync="getLicenceForm(licenceIndex).licenceExpiredDate.isValid"
                    :errMsg.sync="getLicenceForm(licenceIndex).licenceExpiredDate.errMsg"
                  />
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label required">
                  <label>優良認定</label>
                </div>
                <div class="c-inputWrap__items">
                  <div class="c-inputWrap__items__cols">
                    <div class="c-radio">
                      <input
                        :id="'radio-true-' + licenceIndex"
                        type="radio"
                        :name="'radio-group' + licenceIndex"
                        :value="true"
                        v-model="licenceInfo.isExcellent"
                      />
                      <label class="c-radio__label" :for="'radio-true-' + licenceIndex">
                        <span class="c-radio__box"></span>
                        有り
                      </label>
                    </div>
                    <div class="c-radio">
                      <input
                        :id="'radio-false-' + licenceIndex"
                        type="radio"
                        :name="'radio-group' + licenceIndex"
                        :value="false"
                        v-model="licenceInfo.isExcellent"
                      />
                      <label class="c-radio__label" :for="'radio-false-' + licenceIndex">
                        <span class="c-radio__box"></span>
                        無し
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="c-inputWrap" v-if="licenceInfo.wasteTypeId === wasteType.INDUSTRIAL_WASTE">
                <div class="c-inputWrap__label">
                  <label>自治体</label>
                </div>
                <div class="c-inputWrap__items">
                  <p>{{ municipalityList.filter(m => m.licenceNo === licenceInfo.licenceNo).length ? municipalityList.filter(m => m.licenceNo === licenceInfo.licenceNo)[0].municipality : '' }}</p>
                </div>
              </div>
              <div class="c-inputWrap" v-else>
                <div class="c-inputWrap__label required">
                  <label>市区町村</label>
                </div>
                <div class="c-inputWrap__items">
                  <InputText
                      :value.sync="licenceInfo.cityName"
                      inputType="text"
                      placeholder="市区町村"
                      tooltip="100文字以内で入力してください"
                      :validateArg="100"
                      :validation="validateMaxLen"
                      :isValid.sync="getLicenceForm(licenceIndex).cityName.isValid"
                      :errMsg.sync="getLicenceForm(licenceIndex).cityName.errMsg"
                  />
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>許可証PDF</label>
                </div>
                <div class="c-inputWrap__items">
                  <input
                      :id="'file-upload-' + licenceIndex"
                      type="file"
                      accept="application/pdf"
                      @change="(e) => uploadPdf(e, licenceIndex)"
                  />
                  <label class="c-btn secondary upload sm l-item" :for="'file-upload-' + licenceIndex">
                    PDFアップロード
                  </label>
                  <div class="c-inputWrap__items__cols">
                    {{ licenceInfo.licenceFileName }}
                  </div>
                </div>
              </div>

              <div class="c-inputWrap l-area">
                <div class="c-inputWrap__label required">
                  <label>対象廃棄物種類</label>
                </div>
                <div class="c-inputWrap__items">
                  <div
                    class="c-btn secondary func add l-item"
                    @click="showJwnetItemsModalHandler(licenceIndex)"
                  >
                    対象廃棄物種類設定
                  </div>
                  <ul class="l-flex c-inputWrap__items__cols">
                    <li>
                      {{ licenceInfo.targetJwnetItemList.map(item => item.name).join("、") }}
                    </li>
                  </ul>
                  <p v-if="getLicenceForm(licenceIndex).targetJwnetItemList.errMsg" class="c-input__errMsg">{{ getLicenceForm(licenceIndex).targetJwnetItemList.errMsg }}</p>
                </div>
              </div>

              <template v-for="(disposalList, disposalIndex) in licenceInfo.disposalWayList">
                <div class="l-area" :key="'disposalList-' + disposalIndex">
                  <div class="c-inputWrap" :key="'disposalList-' + disposalIndex">
                    <div class="c-inputWrap__label required">
                      <label>処分方法</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <Selectbox
                        class=""
                        name="prefecture"
                        :value.sync="disposalList.disposalWayTypeId"
                        :validation="validateJustBlank"
                        :isValid.sync="getDisposalForm(licenceIndex, disposalIndex).disposalWayTypeId.isValid"
                        :errMsg.sync="getDisposalForm(licenceIndex, disposalIndex).disposalWayTypeId.errMsg"
                      >
                        <option
                          v-for="wayType in disposalWayTypes"
                          :key="wayType.id"
                          :value="wayType.id"
                        >
                          {{ wayType.name }}
                        </option>
                      </Selectbox>
                    </div>
                  </div>
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>詳細処分方法</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <InputText
                        :value.sync="disposalList.disposalDetailWay"
                        inputType="text"
                        placeholder="入力してください"
                        :validation="validateJustBlank"
                        :isValid.sync="getDisposalForm(licenceIndex, disposalIndex).disposalDetailWay.isValid"
                        :errMsg.sync="getDisposalForm(licenceIndex, disposalIndex).disposalDetailWay.errMsg"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <div class="c-inputWrap i-item-t">
                <div class="c-inputWrap__label required"></div>
                <div class="c-inputWrap__items">
                  <div
                    class="c-btn secondary func add l-item"
                    @click="addDisposalsHandler(licenceIndex)"
                  >
                    処分方法と詳細処分方法を追加
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.l-contents -->

          <div class="l-each sm l-box">
            <div class="c-btn secondary func add" @click="addLicenceInfoHandler">
              許可証情報を追加
            </div>
          </div>
        </template>
      </div>

      <div class="l-btns l-container">
        <div class="left">
          <router-link :to="`/disposer/${disposalCompanyId}`" class="c-btn secondary small back">戻る</router-link>
        </div>
        <div class="center">
          <div class="c-btn primary mid" @click="checkRequiredHandler">次へ</div>
        </div>
      </div>
      <!-- /.l-container -->
    </main>

    <!-- 対象廃棄物種類設定のモーダル -->
    <JwnetItemSettingModal
      ref="jwnetItemSetting"
      :isShow.sync="isJwnetItemSettingModalShow"
      :jwnetItemLicences="jwnetItemLicences"
      @setSelectedJwnetItems="setSelectedJwnetItems"
    />

    <!-- 許可証情報削除確認モーダル -->
    <div class="c-modal" v-show="isConfirmDeleteLicenceModal">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          許可証情報を削除します。<br />
          よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div class="c-btn secondary small" @click="isConfirmDeleteLicenceModal = false">戻る</div>
          <div class="c-btn primary small" @click="deleteLicenceHandler">はい</div>
        </div>
      </div>
    </div>

    <!-- 次へ確認モーダル -->
    <div class="c-modal" v-show="isConfirmModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          ここまでの内容を保存し、次の入力に進みます。<br />
          よろしいですか？
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="closeConfirmModalHandler">戻る</div>
          <div class="c-btn primary small" @click="registerHandler">はい</div>
        </ModalFooterButtons>
      </div>
    </div>

    <AddressSelectModal
      :isShow.sync="isAddressSelectModalShow"
      :addresses="addressResults"
      @selectAddress="selectAddressHandler"
    />
    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";

import InputText from "@/components/parts/input/InputText";
import Selectbox from "@/components/parts/input/Selectbox";
import InputDate from "@/components/parts/input/InputDate";
import MyTextarea from "@/components/parts/input/MyTextarea";
import AddressSelectModal from "../components/AddressSelectModal.vue";
import JwnetItemSettingModal from "../components/JwnetItemSettingModal.vue";

import validation from "@/mixin/validation";
import formValueEdit from "../mixins/formValueEdit";
import callApi from "../mixins/callApi";
import addressSearch from "../mixins/form/addressSearch"; // 住所検索処理
import formValidationEdit from "../mixins/form/formValidationEdit";
import { API_URL, DOMAIN } from "@/const";
import common from "@/mixin/common";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";

export default {
  name: "disposer-step1-edit",
  components: {
    ModalFooterButtons,
    InputText,
    Selectbox,
    InputDate,
    MyTextarea,
    AddressSelectModal,
    JwnetItemSettingModal,
  },
  mixins: [
    validation,
    formValueEdit,
    callApi,
    common,
    addressSearch,
    formValidationEdit,
  ],
  data() {
    return {
      disposalCompanyId: null,
      isAddressSelectModalShow: false,
      isJwnetItemSettingModalShow: false,
      isConfirmDeleteLicenceModal: false,
      deleteDisposalLicenceIndex: null,
      isConfirmModalShow: false,
      targetLicenceIndex: null,
      postData: null, // this.formをフォーマットして最終的にpostするデータ
      errMsgs: [],
      municipalityList: [],
      from: null,
      processing: false,
      wasteType: DOMAIN.WASTE_TYPE,
      wasteTypeList: [
        {
          label: "産業廃棄物",
          id: 1,
        },
        {
          label: "一般廃棄物",
          id: 3,
        },
      ],
      hasDisposalLicence: false
    };
  },
  methods: {
    // 得意とするCBA品目を追加押下
    addCbaItemHandler() {
      this.form.cbaItemIdList
          .push(JSON.parse(JSON.stringify(this.dummyForm.cbaItem)));
    },

    /**
     * ステータス変更時
     * 「有効」or「無効」選択 ->is_updateは0
     * 「更新中」選択 （is_updateが1）-> is_activeは1（ただし画面上は有効は選択されていない）
     */
    changeStatusActiveHandler(licenceInfo) {
      licenceInfo.isUpdate = false;
    },
    changeStatusUpdateHandler(licenceInfo) {
      licenceInfo.isActive = true;
    },

    // 許可証情報を追加押下 v-model用form、validate用追加
    addLicenceInfoHandler() {
      this.form.disposalLicenceCreateList
          .push(JSON.parse(JSON.stringify(this.dummyForm.disposalLicence)));
      this.validate.disposalLicenceCreateList
          .push(JSON.parse(JSON.stringify(this.dummyValidate.disposalLicence)));
    },

    // form削除 post用のformのOBJとバリデーション用のPBJを削除
    deleteLicence(licenceIndex) {
      this.deleteDisposalLicenceIndex = licenceIndex;
      this.isConfirmDeleteLicenceModal = true;
    },

    // 許可証モーダルの削除ボタン押下時
    deleteLicenceHandler() {
      this.form.disposalLicenceCreateList.splice(this.deleteDisposalLicenceIndex, 1);
      this.validate.disposalLicenceCreateList.splice(this.deleteDisposalLicenceIndex, 1);
      this.isConfirmDeleteLicenceModal = false;
    },

    // 許可番号フォーカスアウト
    licenceNoBlurHandler(index) {
      const target = this.form.disposalLicenceCreateList[index];
      axios
          .get(API_URL.LICENCE.MUNICIPALITY + `?headNo=${target.licenceNo.substr(0, 3)}`)
          .then((res) => {
            this.municipalityList.push({
              licenceNo: target.licenceNo,
              municipality: res.data.name,
            })
          })
          .catch((err) => {
            console.log(err);
          });
    },

    // 処分系追加 v-model用form、validate用追加
    addDisposalsHandler(licenceIndex) {
      this.form.disposalLicenceCreateList[licenceIndex].disposalWayList
          .push(JSON.parse(JSON.stringify(this.dummyForm.disposalWay)));
      this.validate.disposalLicenceCreateList[licenceIndex].disposalWayList
          .push(JSON.parse(JSON.stringify(this.dummyValidate.disposalWay)));
    },

    // 許可証アップロード
    uploadPdf(e, index) {
      if (!e.target.files[0]) return;
      const file = e.target.files[0] || e.dataTransfer.files[0];
      this.uploadApi(file).then((path) => {
        this.form.disposalLicenceCreateList[index].licenceFilePath = path;
        this.form.disposalLicenceCreateList[index].licenceFileName = file.name;
      });
    },

    // 対象廃棄物種類設定モーダル開く
    showJwnetItemsModalHandler(licenceIndex) {
      this.isJwnetItemSettingModalShow = true;
      this.targetLicenceIndex = licenceIndex;

      this.$refs.jwnetItemSetting.setSelectedJwnetItems(this.form.disposalLicenceCreateList[licenceIndex].targetJwnetItemList);
    },

    // 対象廃棄物種類設定モーダルを設定したときに対象formデータに選択アイテムを挿入
    setSelectedJwnetItems(selectedItems) {
      let targetForm = this.form.disposalLicenceCreateList[this.targetLicenceIndex];
      targetForm.targetJwnetItemList = [];
      selectedItems.map((item) => {
        targetForm.targetJwnetItemList = [
          ...targetForm.targetJwnetItemList,
          { id: item.id, name: item.name },
        ];
      });
      this.validate.disposalLicenceCreateList[this.targetLicenceIndex].targetJwnetItemList.errMsg = "";
      this.targetLicenceIndex = null;
    },

    postForm() {
      this.errMsgs = [];
      this.processing = true; // 2重submit防止

      if ( this.postData.disposalLicenceCreateList !== undefined ) {
      const commonWasteDisposalLicenceCreateList = this.postData.disposalLicenceCreateList.filter((disposalLicenceCreate) => {
        return (
          disposalLicenceCreate.wasteTypeId === this.wasteType.COMMON_WASTE
        )
      });    
      const disposalLicenceCreateList = this.postData.disposalLicenceCreateList.filter((disposalLicenceCreate) => {
        return (
          disposalLicenceCreate.wasteTypeId === this.wasteType.INDUSTRIAL_WASTE
        )
      });    
      this.postData.commonWasteDisposalLicenceCreateList = commonWasteDisposalLicenceCreateList;
      this.postData.disposalLicenceCreateList = disposalLicenceCreateList;
      }

      axios.put(API_URL.DISPOSER.EDIT + this.disposalCompanyId, this.postData)
          .then(() => {
            // ステップ２へ
            if (this.from === "step2-input") {
              this.$router.push(`/disposer/step2/input?disposalCompanyId=${this.disposalCompanyId}`);
            } else {
              this.$router.push(`/disposer/step2/edit/${this.disposalCompanyId}`);
            }
          })
          .catch((err) => {
            this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
            this.processing = false; // 2重submit解除
            this.isConfirmModalShow = false;
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
    },

    /**
     * formデータをフォーマット
     * 都道府県をIDに変換
     * フォーマットされたデータを返す(Promise)
     */
    formatPostData() {
      return new Promise((resolve) => {
        let newData = JSON.parse(JSON.stringify(this.form)); // deep copy
        if (!newData.isLicence) delete newData.disposalLicenceCreateList; // 2021.09.15 許可証の任意対応
        if (!newData.fax) delete newData.fax;
        // 住所の全角変換
        newData.addressInfo.address = this.toFullWidthStringForAddressInfo(newData.addressInfo.address);
        newData.addressInfo.buildingName = this.toFullWidthStringForAddressInfo(newData.addressInfo.buildingName);
        this.prefectures.map((prefecture) => {
          if (prefecture.name === newData.addressInfo.selectedPrefectureTemp) {
            newData.addressInfo.prefecturesId = prefecture.id;
          }
        });
        resolve(newData);
      });
    },

    // 次へ押下
    async checkRequiredHandler() {
      this.errMsgs = [];

      const isValid = await this.runValidate();
      if (!isValid) {
        this.processing = false;
        this.errMsgs.push("入力に誤りがあります。");
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      // 都道府県をIDに変える、不要なプロパティ消す
      this.formatPostData().then((res) => {
        this.isConfirmModalShow = true;
        this.postData = res;
      });
    },

    // モーダル内戻る押下
    closeConfirmModalHandler() {
      this.isConfirmModalShow = false;
      this.postData = null;
    },

    // モーダル内登録押下
    registerHandler() {
      this.postForm();
    },
  },

  mounted() {
    this.disposalCompanyId = +this.$route.params.id;
    this.from = this.$route.query.from;

    this.getDisposerInfo(this.disposalCompanyId)
        .then(() => {
          for (let i = 0; i < this.form.disposalLicenceCreateList.length; i++) {
            this.licenceNoBlurHandler(i);
          }
          if (this.form.disposalLicenceCreateList !== undefined && this.form.disposalLicenceCreateList.length) {
            this.hasDisposalLicence = true
          } else {
            this.hasDisposalLicence = false
          }
        });
    this.getLegalPersonalityTypesApi();
    this.getPrefecturesApi();
    this.getCbaItemsApi();
    this.getDisposalWayTypesApi();
    this.getJwnetItemLicencesApi();
  },
};
</script>
